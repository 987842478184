import { render, staticRenderFns } from "./AdminCampaignReview.vue?vue&type=template&id=4c18c779&scoped=true&"
import script from "./AdminCampaignReview.vue?vue&type=script&lang=ts&"
export * from "./AdminCampaignReview.vue?vue&type=script&lang=ts&"
import style0 from "./AdminCampaignReview.vue?vue&type=style&index=0&id=4c18c779&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c18c779",
  null
  
)

export default component.exports