




































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { DocumentReference } from '@firebase/firestore-types';
import PreviewThumbnail from '@campaigns/shared/components/PreviewThumbnail.vue';

import {
  ScreenCluster,
  SystemStatus,
  PendingApprovalCampaign,
  RejectedCampaign,
  ClustersSchedulesRecord,
  CompanyProfile,
  Nullable,
  AdSpace,
  CampaignMedia, Tuple,
  // CampaignAdSpacesBilling,
} from '@/app/shared/utilities/static-types';
import { getCompanyProfileByUserIdAction } from '@globalActions/CompanyProfileActions';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import namespace from '@/app/shared/store/namespace';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';
import MediaUpload from '@campaigns/shared/components/MediaUpload.vue';
import ClustersAndSchedules from '@/app/shared/components/ClustersAndSchedules/ClustersAndSchedules.vue';
import { cloneDeep } from 'lodash';
import { uploadFileForCampaigns } from '@/app/screens/App/shared/actions';
import { updateAdvertiserCampaignAction } from '@/app/screens/App/screens/Campaigns/shared/actions';
import {
  calculateCampaignPrice,
  findFirstMatchingMediaResolution,
  getCampaignDuration,
  getCampaignSelectedAdSpaces,
  getCampaignStartDate,
  stringifyScreenClustersNames,
} from '@/app/screens/App/screens/Campaigns/shared/utils';
import {
  approveCampaignAction,
  rejectCampaignAction, rejectCampaignChangeMediaRequestAction,
} from '../../shared/actions';
import rules from '@/app/shared/validation-rules';
import { not, uniqueArray } from '@/app/shared/utilities/helper-functions';
import moment from 'moment';
import { loadScreenClusters } from '@/app/shared/actions/ClustersActions';
import { getMediaName, SUPPORTED_TYPES, USER_TYPES_ABBREVIATION, getMediaKeyByResolution, SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { getVideoMediaDuration } from '@/app/shared/utilities/files';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { getUserById } from '@adminRoles/shared/actions';
@Component({
  components: {
    FormComponent,
    PopupMessage,
    ClustersAndSchedules,
    PreviewThumbnail,
    MediaUpload,
  },
  data: (vm: any) => ({
    requiredRule: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class CampaignEdit extends Vue {
  @Getter('getCurrentStoreCampaignRef', { namespace: namespace.CampaignModule })
  public currentRef!: DocumentReference;
  public campaign: Nullable<PendingApprovalCampaign> = null;
  public uploadingMedia: boolean = false;
  public campaignPrice: number = 0;
  public adminPendingCampaignsRoute =
    AdminRoutes.ADVERTISER_PENDING_ADMIN_CAMPAIGNS;

  public campaignName = '';

  public clustersSchedulesRecord: ClustersSchedulesRecord = {};
  public campaignClustersSchedules: ClustersSchedulesRecord = {};
  public screenClustersItems: ScreenCluster[] = [];
  public preSelectedScreenClusters: ScreenCluster[] = [];
  public duration: number = 0;
  public startDate!: Date;
  public screenClusters: ScreenCluster[] = [];
  public selectedScreenClusters: ScreenCluster[] = [];
  public locations: string = '';
  public campaignAdSpaces: AdSpace[] = [];
  public campaignResolutions: Array<AdSpace['SCREENS_RESOLUTION']> = [];

  // public campaignAdSpacesBilling: CampaignAdSpacesBilling = {};
  // public adSpaceBillingTypes = AD_SPACE_BILLING_TYPES;

  public campaignId = '';
  public advertiserId = '';

  public willCreateInvoice: Nullable<boolean> = null;

  public comments = '';
  public commentsErrors: string | string[] = [];

  public campaignStatus: Nullable<SystemStatus> = null;
  public mediaList: CampaignMedia[] = [];
  public resolutionsMedia: Array<Tuple<AdSpace['SCREENS_RESOLUTION'], CampaignMedia>> = [];

  public companyProfile: Nullable<CompanyProfile> = null;
  public createdByStoreOwner: boolean = false;

  public calculateCampaignPriceInfo = calculateCampaignPrice;

  public rejected = true;

  public showConfirmationMessage: (() => Promise<any>) | null = null;

  public goBack(): void {
    this.$router.go(-1);
  }

  public resolutionText({ WIDTH, HEIGHT, UNIT = 'px' }: AdSpace['SCREENS_RESOLUTION']): string {
    return `${WIDTH}${UNIT} ${this.$t('width')} and ${HEIGHT}${UNIT} ${this.$t(
      'height',
    )} `;
  }

  public async uploadCampaignMediaInMediaList(
    selectedFile: File,
    selectedResolution: AdSpace['SCREENS_RESOLUTION'],
  ): Promise<void> {
    try {
      this.uploadingMedia = true;
      const mediaId = uuidv4();
      const uploadedMediaFile = await this.uploadMedia(selectedFile, mediaId);
      const mediaAdSpaces = this.campaignAdSpaces.filter((campaignAdSpace) =>
        selectedResolution.WIDTH === campaignAdSpace.SCREENS_RESOLUTION.WIDTH &&
        selectedResolution.HEIGHT === campaignAdSpace.SCREENS_RESOLUTION.HEIGHT);
      const advertiser = await getUserById(this.campaign!.ADVERTISER_UID);
      const { COMPANY_NAME } = advertiser || {};

      const updatedCampaignMediaFiles = await Promise.all(mediaAdSpaces
        .map(async (adSpace) => {
        const campaignMediaFile = {
          MEDIA_FILE: uploadedMediaFile,
          MEDIA_TYPE: selectedFile.type,
          WIDTH: selectedResolution.WIDTH,
          HEIGHT: selectedResolution.HEIGHT,
          AD_SPACE_ID: adSpace.ID,
          MEDIA_UPLOADED_AT: firebase.firestore.Timestamp.now(),
          MEDIA_ID: mediaId,
          NAME: getMediaName({
            userTypeAbbreviation: USER_TYPES_ABBREVIATION.admin,
            companyName: COMPANY_NAME || '',
            campaignName: this.campaign!.NAME,
            mediaId,
            extension: selectedFile.name.split('.').pop() || '',
          }),
        } as CampaignMedia;

        if (selectedFile.type === SUPPORTED_TYPES.MP4) {
          campaignMediaFile.MEDIA_PLAYBACK_DURATION = await getVideoMediaDuration(selectedFile);
        }

        return campaignMediaFile;
      }));
      const uniqueMediaByResolution = this.mediaList.reduce((result, media) => {
        return {
          ...result,
          [getMediaKeyByResolution(media)]: media,
        };
      }, {} as Record<string, CampaignMedia>);

      const updatedMediaFiles = updatedCampaignMediaFiles.map((updatedMediaFile) => {
        let media = uniqueMediaByResolution[getMediaKeyByResolution(updatedMediaFile)];
        if (media && media!.MEDIA_CHANGE_REQUEST) {
          media.MEDIA_CHANGE_REQUEST = updatedMediaFile;
        } else {
          media = updatedMediaFile;
        }
        return media;
      });

      this.mediaList = this.mediaList.filter((media) =>
        media.WIDTH !== selectedResolution.WIDTH
        && media.HEIGHT !== selectedResolution.HEIGHT);

      this.mediaList = this.mediaList.concat(updatedMediaFiles);

      const updatedCampaign = {
        ...this.campaign!,
        MEDIA_LIST: this.mediaList,
      };

      await updateAdvertiserCampaignAction(updatedCampaign);
    } finally {
      this.uploadingMedia = false;
    }
  }

  public async loadCampaign() {
    try {
      const campaignDoc = await this.currentRef.get();
      if (!campaignDoc.exists) {
        throw new Error('Campaign does not exist');
      }

      this.campaign = campaignDoc.data() as PendingApprovalCampaign;
      this.screenClusters = await loadScreenClusters();
      this.advertiserId = this.campaign!.ADVERTISER_UID!;
      this.createdByStoreOwner = Boolean(this.campaign!.CREATED_BY_STORE_OWNER);
      if (this.advertiserId) {
        this.companyProfile = await getCompanyProfileByUserIdAction(
          this.advertiserId,
        );
      }
      this.campaignId = this.campaign!.ID;
      this.campaignName = this.campaign!.NAME;

      this.campaignClustersSchedules = this.campaign!.SELECTED_CLUSTERS_SCHEDULES;
      this.clustersSchedulesRecord = cloneDeep(this.campaignClustersSchedules);
      this.screenClustersItems = this.campaign!.SCREEN_CLUSTERS;
      this.preSelectedScreenClusters = cloneDeep(this.screenClustersItems);

      this.campaignStatus = this.campaign!.STATUS;
      this.comments = this.campaign!.COMMENTS!;
      this.campaignPrice = this.calculateCampaignPriceInfo(this.campaign);
      this.startDate = getCampaignStartDate(this.campaign);
      this.duration = getCampaignDuration(this.campaign);
      this.selectedScreenClusters =
        this.campaign.SCREEN_CLUSTERS ||
        this.campaign.SCREEN_CLUSTERS_IDS.map(
          (id: string) => this.screenClusters.find(({ ID }) => id === ID)!,
        );
      this.locations = stringifyScreenClustersNames(
        this.selectedScreenClusters,
        this.appLocale,
      );
      this.campaignAdSpaces = await getCampaignSelectedAdSpaces(this.campaign);
      this.campaignResolutions = uniqueArray(this.campaignAdSpaces, (adSpace) => adSpace.SCREENS_RESOLUTION);

      this.mediaList = this.campaign!.MEDIA_LIST || [];
    } catch (err) {
      this.$router.push(this.adminPendingCampaignsRoute);
      // tslint:disable-next-line:no-console
      console.error(`Error loading campaign :: ${(err as Error).message}`);
    }
  }

  public async updateCampaign() {
    // this.campaign!.AD_SPACES_BILLING = this.campaignAdSpacesBilling;

    if (this.rejected) {
      await this.rejectCampaign(this.campaign!);
    } else {
      await this.approveCampaign(this.campaign!);
    }
    this.goBack();
  }

  public async approveCampaign(campaign: PendingApprovalCampaign) {
    try {
      await this.showConfirmationMessage!();
    } catch (err) {
      throw new Error('Campaign approval not confirmed');
    }
    return approveCampaignAction(campaign);
  }

  public async rejectCampaign(campaign: PendingApprovalCampaign) {
    if (!this.comments) {
      this.commentsErrors = 'Required for rejection.';
      throw new Error('You must add comments in case of rejection.');
    }
    if (this.isChangeMediaRequest) {
      return rejectCampaignChangeMediaRequestAction({
        ...campaign,
        COMMENTS: this.comments,
      } as RejectedCampaign);
    }
    return rejectCampaignAction({
      ...campaign,
      COMMENTS: this.comments,
    } as RejectedCampaign);
  }

  // public calculateAutomaticOwnerShareOnAdSpace(adSpace: AdSpace) {
  //   const adSpaceClusterId = adSpace.SCREEN_CLUSTER_REF!.id;
  //   const clusterTotalPrice = calculateClusterTotalPriceOnCampaign(this.campaign!, adSpaceClusterId);
  // return this.campaignAdSpacesBilling[adSpace.ID].OWNER_SHARE =
  //   clusterTotalPrice * (adSpace.CONTRACT_TYPE.SHARE / 100);
  // }

  @Watch('comments')
  public onCommentsChanged(val: string, oldVal: string) {
    if (this.comments) {
      this.commentsErrors = [];
    }
  }

  @Watch('willCreateInvoice')
  public onWillCreateInvoiceChanged(val: boolean) {
    this.campaign!.SKIP_INVOICE = not(val);
  }

  public async uploadMedia(selectedFile: File, mediaId: string): Promise<string | undefined> {
    const uploadTaskRef = await uploadFileForCampaigns(selectedFile, mediaId);
    const taskSnapshot = await uploadTaskRef.put(selectedFile);
    return taskSnapshot && (await taskSnapshot.ref.getDownloadURL());
  }
  get isChangeMediaRequest() {
    return this.campaign!.MEDIA_CHANGE_REQUEST_STATUS && this.campaign!.MEDIA_CHANGE_REQUEST_STATUS!.VAL === SYSTEM_STATUS.NEW.VAL;
  }
  @Watch('mediaList', { deep: true })
  public onChangeMediaList(mediaList: CampaignMedia[]) {
    this.resolutionsMedia = this.campaignResolutions.map((resolution) => {
      const media = findFirstMatchingMediaResolution(mediaList, resolution);
      return [resolution, media] as Tuple<AdSpace['SCREENS_RESOLUTION'], CampaignMedia>;
    });
  }

  public resolutionMedia(resolution: AdSpace['SCREENS_RESOLUTION']) {
    const media = findFirstMatchingMediaResolution(this.mediaList, resolution);
    return media && media.MEDIA_FILE;
  }

  get formattedStartDate() {
    return moment(this.startDate).format('DD-MM-YYYY');
  }

  get appLocale(): string {
    return this.$root.$i18n.locale;
  }

  get isDisabled(): boolean {
    return !this.resolutionsMedia.every(([resolution, media]) => media);
  }
}
